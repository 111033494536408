<template>
    <div class="cg_main"  v-loading="btnLoading"
         element-loading-text="拼命计算中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
        <div style="width: 100%;height: 5px;background: #EFEFEF; "></div>
        <div class="cg_main_son">
            <div style=" display: flow-root;margin-bottom: 25px;margin-top: 25px;">

                <div class="box">
                    <div class="contain">

                        <div class="topTitle">
                            <el-breadcrumb separator="/">
                                <el-breadcrumb-item><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                                <el-breadcrumb-item>需求表配置</el-breadcrumb-item>
                            </el-breadcrumb>
                            <img src="../image/step.png" />
                        </div>
                        <div style="width: 100%;display: flow-root;">
                            <span style="float: left;margin-right: 50px;margin-bottom: 20px;">选择配置</span>
                            <el-checkbox-group v-model="formInline.selectOptionsList" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in cities" :label="city" :key="city" >{{city}}</el-checkbox>
                            </el-checkbox-group>
                        </div>

                        <div class="mainTitle">
                            需求表配置
                            <span style="color: red;font-size: 16px;margin-left: 20px;">柜体与通道填写完成后，其他系统可一键配置</span>


                            <el-popconfirm
                                    @confirm="onSubmitOne"
                                    title="是否采用一键配置？"
                            >
                                <el-button style="width: 80px !important;height: 32px!important;font-size: 12px !important;
    margin-left: 20px;" size="small" type="primary"  slot="reference">一键配置</el-button>
                            </el-popconfirm>
                        </div>
                        <el-form label-width="170px" label-position="right" size="mini">
                            <div ref ="lessonTableImg">
                                <div v-show="jgShow1">
                                    <!-- 第一行 -->
                                    <div class="onePart">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">柜体与通道</div>
                                        </div>
                                        <div class="twoPart">
                                            <div class="subtext">通道类型</div>
                                            <el-form-item label-width="50px">
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.passType1"
                                                >
                                                    <el-option label="冷通道" value="冷通道"></el-option>
                                                    <el-option label="热通道" value="热通道"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label-width="50px">
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.passType2"

                                                >
                                                    <el-option label="单列左" value="单列左"></el-option>
                                                    <el-option label="单列右" value="单列右"></el-option>
                                                    <el-option label="双列" value="双列"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>

                                        <div>
                                            <el-form-item label-width="130px" class="tdkd" label="通道宽度(MM)">
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.passWidth"

                                                >
                                                    <el-option label="1200" value="1200"></el-option>
                                                    <el-option label="1800" value="1800"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第二行 -->
                                    <div class="threePart">

                                        <div>
                                            <div class="subtext jglx">机柜类型</div>
                                            <el-form-item label-width="50px">
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.cabinetType"
                                                        @change="changeColor"

                                                >
                                                    <el-option label="X3" value="X3"></el-option>
                                                    <el-option label="X5" value="X5"></el-option>
                                                    <el-option label="X7" value="X7"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="color">
                                            <span class="subtext">灯带颜色：</span>
                                            <el-select
                                                    class="inputWidth"
                                                    v-model="formInline.lightColor"
                                                    :disabled="lightShow"
                                            >
                                                <el-option label="无" value="无"></el-option>
                                                <el-option label="蓝光" value="蓝光"></el-option>
                                                <el-option label="RGB" value="RGB"></el-option>
                                            </el-select>
                                        </div>

                                        <div>
                                            <el-form-item
                                                    label-width="120px"
                                                    class="subtext"
                                                    label="M型走线槽/柜"
                                            >
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.mCableCount"

                                                >
                                                    <el-option label="0" value="0"></el-option>
                                                    <el-option label="1" value="1"></el-option>
                                                    <el-option label="2" value="2"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第三行 -->
                                    <div class="topbg">
                                        <div class="tableTop">
                                            <div>宽(MM)</div>
                                            <div>深(MM)</div>
                                            <div>U数</div>
                                            <div>单柜功率(KW)</div>
                                            <div>数量(台)</div>
                                            <div>托盘/柜</div>
                                            <div>托架/柜</div>
                                            <div>1U盲板/柜</div>
                                            <div>2U盲板/柜</div>
                                        </div>
                                    </div>
                                    <!-- 第四行 -->
                                    <div class="tablecc">
                                        <div class="subTitle">柜体1尺寸：</div>
                                        <div class="subTitle">柜体2尺寸：</div>
                                    </div>
                                    <div class="tableForm">
                                        <el-form-item label-width="0px" class="subTitle">
                                            <el-select class="oi" v-model="formInline.cabinet1Width" >
                                                <el-option label="600" value="600"></el-option>
                                                <el-option label="800" value="800"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label-width="30px">
                                            <el-select class="oi" v-model="formInline.cabinet1Depth" >
                                                <el-option label="1100" value="1100"></el-option>
                                                <el-option label="1200" value="1200"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <div>
                                            <el-form-item label-width="20px">
                                                <el-select
                                                        class="oi"
                                                        v-model="formInline.cabinet1UCount"

                                                >
                                                    <el-option label="42U" value="42"></el-option>
                                                    <el-option label="47U" value="47"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <el-form-item label-width="px">
                                                <el-select @change="gl1Change" class="oi" v-model="formInline.cabinet1Power" >
                                                    <el-option label="0" value="0"></el-option>
                                                    <el-option label="3" value="3"></el-option>
                                                    <el-option label="5" value="5"></el-option>
                                                    <el-option label="8" value="8"></el-option></el-select>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <el-form-item label-width="-0px">
                                                <el-input @blur="sl1Change" class="pi" v-model="formInline.cabinet1Count"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <el-form-item label-width="-20px">
                                                <el-input class="pi" v-model="formInline.cabinet1Tray"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <el-form-item label-width="-40px">
                                                <el-input class="pi" v-model="formInline.cabinet1Bracket"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div>
                                            <el-form-item label-width="-60px">
                                                <el-input class="pi" v-model="formInline.cabinet1Plate1u"></el-input>
                                            </el-form-item>
                                        </div>

                                        <div>
                                            <el-form-item label-width="-70px">
                                                <el-input class="pi" v-model="formInline.cabinet1Plate2u"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <!-- 第五行 -->

                                    <div class="tableForm table2">
                                        <el-form-item label-width="0px" class="subTitle">
                                            <el-select class="oi" v-model="formInline.cabinet2Width" >
                                                <el-option label="600" value="600"></el-option>
                                                <el-option label="800" value="800"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label-width="30px">
                                            <el-select class="oi" v-model="formInline.cabinet2Depth" >
                                                <el-option label="1100" value="1100"></el-option>
                                                <el-option label="1200" value="1200"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label-width="20px">
                                            <el-select
                                                    class="oi"
                                                    v-model="formInline.cabinet2UCount"
                                                    placeholder="2000(42U)"
                                            >
                                                <el-option label="42U" value="42"></el-option>
                                                <el-option label="47U" value="47"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label-width="20px">
                                            <el-select @change="gl2Change" class="oi" v-model="formInline.cabinet2Power" placeholder="0">
                                                <el-option label="0" value="0"></el-option>
                                                <el-option label="3" value="3"></el-option>
                                                <el-option label="5" value="5"></el-option>
                                                <el-option label="8" value="8"></el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label-width="40px">
                                            <el-input @blur="sl2Change" class="pi" v-model="formInline.cabinet2Count"></el-input>
                                        </el-form-item>

                                        <el-form-item label-width="49px">
                                            <el-input class="pi" v-model="formInline.cabinet2Bracket"></el-input>
                                        </el-form-item>

                                        <el-form-item label-width="51px">
                                            <el-input class="pi" v-model="formInline.cabinet2Tray"></el-input>
                                        </el-form-item>

                                        <el-form-item label-width="50px">
                                            <el-input class="pi" v-model="formInline.cabinet2Plate1u"></el-input>
                                        </el-form-item>

                                        <el-form-item label-width="60px">
                                            <el-input class="pi" v-model="formInline.cabinet2Plate2u"></el-input>
                                        </el-form-item>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第六行 -->

                                    <div class="tdtc compact-el-item-container">
                                        <div class="sixPart">
                                            <div class="flex-center mb12">
                                                <el-form-item
                                                        label-width="95px"
                                                        class="subtext"
                                                        label="通道天窗："
                                                >
                                                    <el-col>
                                                        <el-select
                                                                v-model="formInline.passSkylight"
                                                        >
                                                            <el-option label="直角（手动）" value="直角（手动）"></el-option>
                                                            <el-option :disabled="zjShow" label="直角（自动）" value="直角（自动）"></el-option>
                                                            <el-option label="弧形（手动" value="弧形（手动）"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>

                                                <el-form-item class="subtext" label="热围框高度(mm)：">
                                                    <el-col>
                                                        <el-input v-model="formInline.hotFrameHeight"></el-input>
                                                    </el-col>
                                                </el-form-item>

                                                <div class="subtext">机柜顶部至吊顶下方的距离（评估热通道）</div>
                                                <div class="clear"></div>
                                            </div>
                                            <!-- 第七行 -->
                                            <div class="flex-center mb12">
                                                <div class="subtext">天花板与地面完成面高度（评估冷通道）</div>
                                                <div class="floatbox">
                                                    <div class="flex-center mb12">
                                                        <div class="thirdtext">
                                                            机柜顶部至吊顶低部的有效空间，高度：
                                                        </div>
                                                        <el-form-item label-width="0px">
                                                            <el-input
                                                                    class="spaceheight"
                                                                    v-model="formInline.heightGap"
                                                                    @change="highChange"
                                                            ></el-input>
                                                        </el-form-item>
                                                        <div class="thirdtext">
                                                            高度大于500mm,小于不建议采用通道）。
                                                        </div>
                                                        <div class="clear"></div>
                                                    </div>
                                                    <div class="lasttext">
                                                        （700MM以上，采用300支撑高单开天窗，700-500MM之间，采用200支撑高双开天窗）
                                                    </div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>

                                        <!-- 第八行 -->
                                        <div class="flex-center mb12">
                                            <el-form-item
                                                    label-width="178px"
                                                    class="subtext"
                                                    label="柱子是否嵌入通道位置:">
                                                <el-select
                                                        v-model="formInline.pillarPosition"
                                                        @change="zzChange"
                                                >
                                                    <el-option label="无柱子" value="无柱子"></el-option>
                                                    <el-option label="柱子嵌入柜体后部" value="柱子嵌入柜体后部"></el-option>
                                                    <el-option label="柱子嵌入柜体中间" value="柱子嵌入柜体中间"></el-option>
                                                    <el-option label="柱子嵌入柜体前面" value="柱子嵌入柜体前面"></el-option>
                                                    <el-option label="柱子嵌入通道中间" value="柱子嵌入通道中间"></el-option>

                                                </el-select>
                                            </el-form-item>
                                            <el-form-item class="subtext" label-width="128px" label="数量:">
                                                <el-input class="pi" v-model="formInline.pillarCount"></el-input>
                                            </el-form-item>
                                            <el-form-item class="subtext" label-width="128px" label="假柜体尺寸:">
                                                <!--<el-input disabled="" class="pi" v-model="formInline.falseCabinet"></el-input>-->
                                                <el-select
                                                        v-model="formInline.falseCabinet"
                                                        :disabled="zzShow"
                                                >
                                                    <el-option label="无柱子" value="无柱子"></el-option>
                                                    <el-option label="300" value="300"></el-option>
                                                    <el-option label="600" value="600"></el-option>
                                                    <el-option label="800" value="800"></el-option>
                                                    <el-option label="1200" value="1200"></el-option>

                                                </el-select>
                                            </el-form-item>
                                        </div>

                                        <!-- 第十行 -->
                                        <div class="flex-center mb12">
                                            <el-form-item label-width="82px" class="subtext" label="移门类型:">
                                                <el-col :span="20">
                                                    <el-select
                                                            v-model="formInline.slidedoorType"
                                                            @change="ymChange"
                                                    >
                                                        <el-option label="自动平移（标配)" value="自动平移"></el-option>
                                                        <el-option label="手动推拉" value="手动推拉"></el-option>
                                                    </el-select>
                                                </el-col>
                                            </el-form-item>
                                            <!--默认-->
                                            <el-form-item class="subtext" label="显示屏:">
                                                <el-col :span="20">
                                                    <el-select v-model="formInline.screenSize" >
                                                        <el-option label="无" value=""></el-option>
                                                        <el-option label="10屏(标配)" value="10"></el-option>
                                                        <el-option label="21.5屏" value="21.5"></el-option>
                                                    </el-select>
                                                </el-col>
                                            </el-form-item>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>


                                <div v-show="jgShow2">
                                    <!-- 第十一行 -->
                                    <div class="titlebox">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">配电系统</div>
                                        </div>
                                        <div class="yzbpi">
                                            标配模式：UPS容量根据负荷自动默认机型，带UPS输入/输出配电功能及IT配电功能，后备时间：30分钟，铅酸蓄电池，电池通道外安装，PDU标配24位或16位国标插座，
                                            不带工业连接器，不配电缆。
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第十二行 -->
                                    <div class="pdxtpart">
                                        <!-- <div class="part11">
                                            <div class="subTitle">市电概况</div>
                                            <el-form-item label-width="56px">
                                                <el-col :span="15">
                                                    <el-select v-model="formInline.cityEleType" >
                                                        <el-option label="单路市电" value="单路"></el-option>
                                                        <el-option label="双路市电" value="双路"></el-option>
                                                    </el-select>
                                                </el-col>
                                            </el-form-item>
                                            <div class="clear"></div>
                                        </div> -->
                                        <!-- 第十三行 -->
                                        <div class="form-container">
                                            <div class="subTitle">UPS系统</div>
                                            <div class="form-section compact-el-item-container">
                                              <el-form-item label-width="100px" class="subtext" label="类型:">
                                                <el-select

                                                        class="oi"
                                                        v-model="formInline.upsType"
                                                        placeholder=""
                                                        @change="rlChange"
                                                >
                                                    <el-option label="一体化UPS" value="一体化UPS"></el-option>
                                                    <el-option label="模块化UPS" value="模块化UPS"></el-option>
                                                    <el-option label="塔式UPS" value="塔式UPS"></el-option>

                                                </el-select>
                                            </el-form-item>

                                            <el-form-item class="subtext" label="UPS容量指定:">
                                                <el-select
                                                        v-show="rlShow1"
                                                        class="oi"
                                                        v-model="formInline.upsCapacity"
                                                        placeholder=""
                                                >
                                                    <el-option label="60KVA" value="60"></el-option>
                                                    <el-option label="90KVA" value="90"></el-option>
                                                    <el-option label="100KVA" value="100"></el-option>
                                                    <el-option label="120KVA" value="120"></el-option>
                                                    <el-option label="150KVA" value="150"></el-option>
                                                </el-select>
                                                <el-select
                                                        v-show="rlShow2"
                                                        class="oi"
                                                        v-model="formInline.upsCapacity"
                                                        placeholder=""
                                                >
                                                    <el-option label="100KVA" value="100"></el-option>
                                                    <el-option label="150KVA" value="150"></el-option>
                                                    <el-option label="200KVA" value="200"></el-option>
                                                    <el-option label="300KVA" value="300"></el-option>
                                                    <el-option label="400KVA" value="400"></el-option>
                                                    <el-option label="500KVA" value="500"></el-option>
                                                    <el-option label="600KVA" value="600"></el-option>
                                                    <el-option label="800KVA" value="800"></el-option>
                                                </el-select>
                                                <el-select
                                                        v-show="rlShow3"
                                                        class="oi"
                                                        v-model="formInline.upsCapacity"
                                                        placeholder=""
                                                >
                                                    <el-option label="100KVA" value="100"></el-option>
                                                    <el-option label="150KVA" value="150"></el-option>
                                                    <el-option label="200KVA" value="200"></el-option>
                                                    <el-option label="250KVA" value="250"></el-option>
                                                    <el-option label="300KVA" value="300"></el-option>
                                                    <el-option label="400KVA" value="400"></el-option>
                                                    <el-option label="500KVA" value="500"></el-option>
                                                    <el-option label="600KVA" value="600"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item class="subtext" label="冗余系统:">
                                                <el-select
                                                        @change="ryChange"
                                                        label-width="100px"
                                                        class="oi"
                                                        v-model="formInline.upsRedundanceSystem"
                                                        placeholder=""
                                                >
                                                    <el-option label="N系统" value="N系统"></el-option>
                                                    <el-option label="N+1系统" value="N+1系统"></el-option>
                                                    <el-option label="2N系统" value="2N系统" :disabled="option2NDisable"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <div>
                                                <el-form-item  label="安装位置:">
                                                    <el-select
                                                            disabled
                                                            class="outside"
                                                            v-model="formInline.passPosition"
                                                    >
                                                        <el-option label="通道内" value="通道内"></el-option>
                                                        <el-option label="通道外" value="通道外"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <!-- </el-form> -->
                                            </div>
                                            </div>
                                        </div>
                                        <!-- 第十四行 -->
                                        <div class="part14">
                                            <div class="subTitle xdc">蓄电池</div>
                                            <div class="form-section">
                                                <div class="kk compact-el-item-container flex-center" style="margin-bottom: 0px;height: 58px;">
                                                    <el-form-item

                                                            label-width="120px"
                                                            class="subtext"
                                                            label="蓄电池类型：" >
                                                        <el-col :span="20">
                                                            <el-select
                                                                    v-model="formInline.batteryType"
                                                                    placeholder="">
                                                                <el-option label="铅酸蓄电池（标配）" value="铅酸蓄电池"></el-option>
                                                                <!--<el-option label="锂蓄电池" value="锂蓄电池"></el-option>-->
                                                            </el-select>
                                                        </el-col>
                                                    </el-form-item>

                                                    <el-form-item
                                                            label-width="140px"
                                                            class="subtext"
                                                            label="蓄电池安装方式：">
                                                        <el-select
                                                                class="oiii"
                                                                v-model="formInline.batteryInstallType"
                                                                placeholder="">
                                                            <el-option label="通道内（120AH以下）" value="通道内（120AH以下）"></el-option>
                                                            <el-option label="通道外" value="通道外"></el-option>
                                                        </el-select>
                                                    </el-form-item>

                                                    <el-form-item
                                                            label-width="140px"
                                                            class="subtext"
                                                            label="蓄电池后备时间：">
                                                        <el-select
                                                                class="spaceheight"
                                                                @change="gl1Change2"
                                                                v-model="formInline.batteryTime"
                                                                placeholder=""
                                                        ><el-option label="15min" value="15"></el-option>
                                                            <el-option label="30min" value="30"></el-option>
                                                            <el-option label="60min" value="60"></el-option>
                                                            <el-option label="120min" value="120"></el-option>
                                                            <el-option label="180min" value="180"></el-option>
                                                            <el-option label="240min" value="240"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <div class="clear"></div>
                                                </div>
                                                <div class="compact-el-item-container flex-center">
                                                    <el-form-item
                                                            label-width="205px"
                                                            class="subtext"
                                                            label="蓄电池后备时间计算基准:"
                                                    >
                                                        <el-col :span="19">
                                                            <el-select
                                                                    v-model="formInline.batteryTimeBase"
                                                                    placeholder="">
                                                                <el-option label="按照实际容量计算" value="按照实际容量计算"></el-option>
                                                                <el-option label="按照UPS容量计算" value="按照UPS容量计算"></el-option>
                                                            </el-select>
                                                        </el-col>
                                                    </el-form-item>
                                                    <el-form-item
                                                            label-width="106px"
                                                            class="subtext"
                                                            label="指定节数："
                                                    >
                                                        <!-- <el-input class="spaceheight" v-model="formInline.batteryCount"
                                                         ><i
                                                                 slot="suffix"
                                                                 style="font-style: normal; margin-right: 10px"
                                                         >节</i
                                                         ></el-input
                                                         >-->
                                                        <el-select
                                                                v-model="formInline.batteryCount"
                                                                @change="gl1Change3"
                                                                placeholder="">
                                                            <el-option label="32" value="32"></el-option>
                                                            <el-option label="36" value="36"></el-option>
                                                            <el-option label="40" value="40"></el-option>
                                                            <el-option label="44" value="44"></el-option>
                                                            <el-option label="50" value="50"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item
                                                            label-width="110px"
                                                            class="subtext"
                                                            label="指定容量："
                                                    >
                                                        <!--  <el-input class="spaceheight" v-model="formInline.batteryCapacity"
                                                          ><i
                                                                  slot="suffix"
                                                                  style="font-style: normal; margin-right: 10px"
                                                          >AH</i
                                                          ></el-input
                                                          >-->
                                                        <el-select
                                                                v-model="formInline.batteryCapacity"

                                                                placeholder="">
                                                            <el-option label="65AH" value="65"></el-option>
                                                            <el-option label="100AH" value="100"></el-option>
                                                            <el-option label="120AH" value="120"></el-option>
                                                            <el-option label="150AH" value="150"></el-option>
                                                            <el-option label="200AH" value="200"></el-option>
                                                            <el-option label="250AH" value="250"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <el-form-item
                                                            label-width="100px"
                                                            class="subtext"
                                                            label="级数："
                                                    >
                                                        <!--<el-input class="spaceheight" v-model="formInline.batteryLevel"-->
                                                        <!--&gt;<i-->
                                                        <!--slot="suffix"-->
                                                        <!--style="font-style: normal; margin-right: 10px"-->
                                                        <!--&gt;组</i-->
                                                        <!--&gt;</el-input-->
                                                        <!--&gt;-->
                                                        <el-select
                                                                v-model="formInline.batteryLevel"

                                                                placeholder="">
                                                            <el-option label="1组" value="1"></el-option>
                                                            <el-option label="2组" value="2"></el-option>
                                                            <el-option label="3组" value="3"></el-option>
                                                            <el-option label="4组" value="4"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                    <div class="clear"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 第十五行 -->
                                        <div class="form-container">
                                            <div class="subTitle">配电方式</div>
                                            <div class="form-section compact-el-item-container">
                                              <el-form-item
                                                    label-width="267px"
                                                    class="subtext"
                                                    label="UPS输入/输出柜供电方式： "
                                            >
                                                <el-select

                                                        class="inputWidth"
                                                        v-model="formInline.desUpsPowerType"
                                                        placeholder=""
                                                >
                                                    <el-option label="单路市电" value="单路"></el-option>
                                                    <el-option label="双路市电" value="双路"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item class="subtext" label="IT柜供电方式：">
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.desItPowerType"
                                                        placeholder=""
                                                >
                                                    <el-option label="单路" value="单路"></el-option>
                                                    <el-option label="双路" value="双路"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            </div>
                                        </div>
                                        <!-- 第十六行 -->
                                        <div v-show="jmShow" class="form-container">
                                            <div class="subTitle">精密配电柜</div>
                                            <div class="form-section compact-el-item-container">
                                              <el-form-item
                                                    label-width="143px"
                                                    class="subtext"
                                                    label="总输入容量："
                                            >
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.powerCabinetCapacity"
                                                        placeholder=""
                                                >
                                                    <el-option label="160A" value="160"></el-option>
                                                    <el-option label="250A" value="250"></el-option>
                                                    <el-option label="400A" value="400"></el-option>
                                                </el-select>
                                            </el-form-item>

                                            <el-form-item
                                                    label-width="196px"
                                                    class="subtext"
                                                    label="支路数量(总输出数量) :"
                                            >
                                                <el-select
                                                        class="inputWidth"
                                                        v-model="formInline.powerCabinetCount"
                                                        placeholder=""
                                                >
                                                    <el-option label="24" value="24"></el-option>
                                                    <el-option label="36" value="36"></el-option>
                                                    <el-option label="48" value="48"></el-option>
                                                    <el-option label="72" value="72"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            </div>
                                        </div>
                                        <!-- 第十七行 -->
                                        <div class="form-container">
                                            <div class="subTitle">PDU（坚装）</div>
                                            <div class="form-section compact-el-item-container flex-center">
                                                <!-- <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="插座标准："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.pduStandard"
                                                          placeholder=""
                                                  >
                                                      <el-option label="国标" value="国标"></el-option>
                                                      <el-option label="IEC320" value="IEC320"></el-option>
                                                  </el-select>
                                              </el-form-item>-->

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="输入电流："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.inputCurrent"
                                                          placeholder=""
                                                  >
                                                      <el-option label="10A" value="10"></el-option>
                                                      <el-option label="16A" value="16"></el-option>
                                                      <el-option label="32A" value="32"></el-option>
                                                      <el-option label="63A" value="63"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="输入电压："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.inputVoltage"
                                                          placeholder=""
                                                  >
                                                      <el-option label="250V" value="250"></el-option>
                                                      <el-option label="380V" value="380"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="插座1类型："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.socket1Type"
                                                          placeholder=""
                                                          @change="changeSocket1Type"
                                                  >
                                                      <el-option label="国标10A三扁" value="国标10A三扁"></el-option>
                                                      <el-option label="新国标10A五孔" value="新国标10A五孔"></el-option>
                                                      <el-option label="国标16A三扁" value="国标16A三扁"></el-option>
                                                      <el-option label="IEC320 C13" value="IEC320 C13"></el-option>
                                                      <el-option label="IEC320 C19" value="IEC320 C19"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="插座1数量："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.socket1Count"
                                                          placeholder=""
                                                          @change="cz1Change"
                                                  >
                                                      <el-option label="4" value="4"></el-option>
                                                      <el-option label="8" value="8"></el-option>
                                                      <el-option label="12" value="12"></el-option>
                                                      <el-option label="16" value="16"></el-option>
                                                      <el-option label="20" value="20"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="插座2类型："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.socket2Type"
                                                          placeholder=""
                                                          :disabled="socket2TypeDisable"
                                                  >
                                                      <el-option label="国标10A三扁" value="国标10A三扁"></el-option>
                                                      <el-option label="新国标10A五孔" value="新国标10A五孔"></el-option>
                                                      <el-option label="国标16A三扁" value="国标16A三扁"></el-option>
                                                      <el-option label="IEC320 C13" value="IEC320 C13"></el-option>
                                                      <el-option label="IEC320 C19" value="IEC320 C19"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <el-form-item
                                                      label-width="105px"
                                                      class="subtext"
                                                      label="插座2数量："
                                              >
                                                  <el-select
                                                          class="oi"
                                                          v-model="formInline.socket2Count"
                                                          placeholder=""
                                                          @change="cz2Change"
                                                          :disabled="socket2CountDisable"
                                                  >
                                                      <el-option label="4" value="4"></el-option>
                                                      <el-option label="8" value="8"></el-option>
                                                      <el-option label="12" value="12"></el-option>
                                                      <el-option label="16" value="16"></el-option>
                                                      <el-option label="20" value="20"></el-option>
                                                  </el-select>
                                              </el-form-item>

                                              <!-- <el-form-item class="subtext" label-width="128px" label="插座总数量:">
                                                  <el-input disabled="" class="pi" v-model="formInline.socketTotal"></el-input>
                                              </el-form-item> -->


                                              <el-form-item
                                                      label-width="176px"
                                                      class="subtext"
                                                      label="是否带工业连接器："
                                              >
                                                  <el-select
                                                          class="pi"
                                                          v-model="formInline.pduWithConnect"
                                                          placeholder=""
                                                  >
                                                      <el-option label="否" value="否"></el-option>
                                                      <el-option label="是" value="是"></el-option>
                                                  </el-select>
                                              </el-form-item>
                                              <div class="clear"></div>
                                            </div>
                                        </div>
                                        <!-- 第十八行 -->
                                        <div class="form-container">
                                            <div class="subTitle">电缆</div>
                                            <div class="form-section compact-el-item-container">
                                              <el-form-item label-width="95px">
                                                <el-select
                                                        class="oii"
                                                        v-model="formInline.cable"
                                                        placeholder=""
                                                >
                                                    <el-option label="甲供" value="甲供"></el-option>
                                                    <el-option label="按照我司规则标配" value="按照我司规则标配"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <div class="yzbpi">
                                                一舟标配：1、UPS输入输出柜的电缆2、UPS输出输出至精密配电柜的电缆3、精密配电柜到PDU的电缆4、UPS至电池开关箱的电缆5、空调内机至外机的电缆
                                                以上电缆均标配5米，超出5米用户自己负责
                                            </div>
                                            </div>
                                        </div>
                                        <!-- 第十九行 -->
                                        <div class="form-container">
                                            <div class="subTitle">其他说明</div>
                                            <div class="gang" style="width: 300px">
                                                <el-input  type="textarea" style="width: 400px !important;" class="spaceheight" v-model="formInline.otherRemark1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div v-show="jgShow3">
                                    <!-- 第二十行 -->
                                    <div class="titlebox">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">制冷系统</div>
                                        </div>
                                        <div class="yzbp">
                                            标配：行级空调，水平送风，带低温组件、铜管辅材（不含电缆）：30米，
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第二十一行-->
                                    <div class="part14">
                                        <div class="subTitle ktlx">空调类型</div>
                                        <div class="form-section">
                                            <div class="compact-el-item-container flex-center mb12" style="margin-top:18px">
                                                <el-form-item label-width="80px" class="subtext" label="类型:">
                                                    <el-col :span="20">
                                                        <el-select v-model="formInline.conType" placeholder="" @change="ktChange">
                                                            <el-option label="行级空调" value="行级空调"></el-option>
                                                            <el-option label="房级空调" value="房级空调"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item
                                                        label-width="90px"
                                                        class="subtext"
                                                        label="安装位置:"
                                                >
                                                    <el-col :span="20">
                                                        <el-select disabled  v-model="formInline.conPosition" placeholder="">
                                                            <el-option label="通道内" value="通道内"></el-option>
                                                            <el-option label="通道外" value="通道外"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item
                                                        class="subtext"
                                                        label-width="90px"
                                                        label="冗余方式:"
                                                >
                                                    <el-col :span="20">
                                                        <el-select v-model="formInline.conRedundance" placeholder="">
                                                            <el-option label="N系统" value="N系统"></el-option>
                                                            <el-option label="N+1系统" value="N+1系统"></el-option>
                                                            <el-option label="2N系统" value="2N系统"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="compact-el-item-container flex-center mb12">
                                                <el-form-item label-width="80px" class="subtext" label="容量:">
                                                    <!-- <el-col :span="11">
                                                         <el-select
                                                                 class="oi hou"
                                                                 v-model="formInline.conCapacityType"
                                                                 placeholder=""
                                                         >
                                                             <el-option label="自动选型" value="自动选型"></el-option>
                                                             <el-option label="指定容量" value="指定容量"></el-option>
                                                         </el-select>
                                                     </el-col>

                                                     <el-col class="line" :span="2">-</el-col>-->
                                                    <el-col :span="11">
                                                        <el-select
                                                                v-show="ktSelect"
                                                                class="oi hou"
                                                                v-model="formInline.conCapacity"
                                                                placeholder=""
                                                        >
                                                            <el-option label="25" value="25"></el-option>
                                                            <el-option label="40" value="40"></el-option>
                                                            <el-option label="50" value="50"></el-option>
                                                        </el-select>
                                                        <el-select
                                                                v-show="!ktSelect"
                                                                class="oi hou"
                                                                v-model="formInline.conCapacity"
                                                                placeholder=""
                                                        >
                                                            <el-option label="12.5" value="12.5"></el-option>
                                                            <el-option label="25" value="25"></el-option>
                                                            <el-option label="30" value="30"></el-option>
                                                            <el-option label="35" value="35"></el-option>
                                                            <el-option label="40" value="40"></el-option>
                                                            <el-option label="45" value="45"></el-option>
                                                            <el-option label="50" value="50"></el-option>
                                                            <el-option label="60" value="60"></el-option>
                                                            <el-option label="70" value="70"></el-option>
                                                            <el-option label="80" value="80"></el-option>
                                                            <el-option label="90" value="90"></el-option>
                                                            <el-option label="100" value="100"></el-option>
                                                            <el-option label="120" value="120"></el-option>
                                                            <el-option label="130" value="130"></el-option>
                                                            <el-option label="150" value="150"></el-option>
                                                            <el-option label="180" value="180"></el-option>
                                                            <el-option label="200" value="200"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>

                                                <el-form-item label-width="60px" class="subtext" label="数量：">
                                                    <el-col :span="8">
                                                        <el-select
                                                                class="hou"
                                                                v-model="formInline.conCountType"
                                                                placeholder=""
                                                        >
                                                            <el-option label="自动配置" value="自动配置"></el-option>
                                                            <el-option label="指定数量" value="指定数量"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                    <el-col class="line" :span="2">-</el-col>
                                                    <el-col :span="6">
                                                        <el-input v-model="formInline.conCount"></el-input>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item class="subtext" label="低温：">
                                                    <el-col :span="20">
                                                        <el-select
                                                                class="oi"
                                                                v-model="formInline.lowTemp"
                                                                placeholder=""
                                                        >
                                                            <el-option label="是" value="是"></el-option>
                                                            <el-option label="否" value="否"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="compact-el-item-container flex-center mb12">
                                                <el-form-item class="subtext" label="制冷剂是否配置：">
                                                    <el-col :span="20">
                                                        <el-select
                                                                class="oi"
                                                                v-model="formInline.conCryogen"
                                                                placeholder=""
                                                        >
                                                            <el-option label="是" value="是"></el-option>
                                                            <el-option label="否" value="否"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item class="subtext" label="冷冻油是否配置：">
                                                    <el-col :span="20">
                                                        <el-select
                                                                class="oi"
                                                                v-model="formInline.conFreezShaft"
                                                                placeholder=""
                                                        >
                                                            <el-option label="是" value="是"></el-option>
                                                            <el-option label="否" value="否"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                            </div>
                                            <div class="compact-el-item-container flex-center mb12">
                                                <el-form-item
                                                        label-width="127px"
                                                        class="subtext"
                                                        label="送风方式："
                                                >
                                                    <el-col :span="20">
                                                        <el-select
                                                                v-show="ktSelect"
                                                                class="oi"
                                                                style="width: 300px"
                                                                v-model="formInline.conAirSupply"
                                                                placeholder=""
                                                        >
                                                            <el-option label="正前送风" value="正前送风"></el-option>


                                                        </el-select>

                                                        <el-select
                                                                v-show="!ktSelect"
                                                                class="oi"
                                                                style="width: 300px"
                                                                v-model="formInline.conAirSupply"
                                                                placeholder=""
                                                        >
                                                            <el-option label="上前送风" value="上前送风"></el-option>
                                                            <el-option label="上送风，正面回风" value="上送风，正面回风"></el-option>
                                                            <el-option label="下沉式风机送风，上回风" value="下沉式风机送风，上回风"></el-option>
                                                            <el-option label="正前送风" value="正前送风"></el-option>


                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item class="subtext" label="空调安装铜管辅材">
                                                    <el-col :span="20">
                                                        <el-select
                                                                class="oi"
                                                                v-model="formInline.conInstallMaterial"
                                                                placeholder=""
                                                        >
                                                            <el-option label="不配" value="不配"></el-option>
                                                            <el-option label="10米" value="10米"></el-option>
                                                            <el-option label="20米" value="20米"></el-option>
                                                            <el-option label="30米" value="30米"></el-option>
                                                            <el-option label="40米" value="40米"></el-option>
                                                            <el-option label="50米" value="50米"></el-option>
                                                            <el-option label="60米" value="60米"></el-option>
                                                        </el-select>
                                                    </el-col>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </div>

                                <div v-show="jgShow4">
                                    <!-- 第二十二行 -->
                                    <div class="titlebox">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">动环监控系统</div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第二十三行 -->
                                    <!--<div>
                                        <div class="subTitle bpdsq">标配的授权</div>
                                        <div class="ltt">
                                            <el-checkbox-group class="checkboxml" v-model="formInline.standardAuthorList">
                                                <el-checkbox label="UPS设备" ></el-checkbox>
                                                <el-checkbox label="智能配电柜" ></el-checkbox>
                                                <el-checkbox label="精密空调" ></el-checkbox>
                                                <el-checkbox label="温湿度" ></el-checkbox>
                                                <el-checkbox label="水浸" ></el-checkbox>
                                                <el-checkbox label="烟感" ></el-checkbox>
                                                <el-checkbox label="报警器" ></el-checkbox>
                                                <el-checkbox label="通道门禁" ></el-checkbox>
                                                <el-checkbox label="视屏监控" ></el-checkbox>
                                                <el-checkbox label="短信功能" ></el-checkbox>
                                            </el-checkbox-group>
                                            &lt;!&ndash; <div>UPS设备</div>
                                             <div>智能配电柜</div>
                                             <div>精密空调</div>
                                             <div>温湿度</div>
                                             <div>水浸</div>
                                             <div>烟感</div>
                                             <div>报警器</div>
                                             <div>通道门禁</div>
                                             <div>视屏监控</div>
                                             <div>短信功能</div>&ndash;&gt;
                                        </div>
                                        <div class="clear"></div>
                                    </div>-->
                                    <!-- 第二十四行 -->
                                    <div style="margin-top: 20px" class="form-container mb12">
                                        <div class="subTitle">系统选择</div>
                                        <div class="form-section compact-el-item-container">
                                          <el-form-item label-width="55px">
                                            <el-col :span="13">
                                                <el-select
                                                        class="hou"
                                                        v-model="formInline.devOps"
                                                        placeholder=""
                                                        style="width: 200px"
                                                        @change="xtChange"
                                                >
                                                    <el-option label="基础动环" value="基础动环"></el-option>
                                                    <el-option label="运维平台" value="运维平台"></el-option>
                                                    <el-option label="DCIM平台" value="DCIM平台"></el-option>
                                                </el-select>
                                            </el-col>
                                            <!--  <el-col class="line" :span="2">-</el-col>
                                          <el-col :span="9">无3D功能 </el-col>-->
                                        </el-form-item>
                                        <div class="yzbpi" v-show="xtShow1">
                                            基础动环: -舟提供的ups,精密空调,精密配电柜、温湿度,漏水,烟感，门禁,北向接口、手机APP、显示终端、短信、声光
                                        </div>
                                        <div class="yzbpi" v-show="xtShow2">可支持资产管理、量管理功能，多模块监控平台10个以内</div>
                                        <div class="yzbpi" v-show="xtShow3">3D、资产管理、能效管理、容量管理、IT运维、 监控系统硬件拓扑、定制报表、邮件通知</div>
                                        </div>
                                    </div>
                                    <!-- 第二十五行 -->
                                    <div class="form-container">
                                      <div class="subTitle">功能选项</div>
                                    <div style="position: relative" class="form-section compact-el-item-container">
                                        <el-form-item label-width="30px" class="subTitle" prop="type" style="margin-left:0">
                                            <el-checkbox-group class="checkboxml" @change="gnChange" v-model="formInline.functionOptionsList">
                                                <el-checkbox label="视频监测" ></el-checkbox>
                                                <el-checkbox label="蓄电池监测" ></el-checkbox>
                                                <el-checkbox label="天窗状态监测" ></el-checkbox>
                                                <el-checkbox label="智能PDU接入授权" ></el-checkbox>
                                                <el-checkbox label="电量仪接入授权" ></el-checkbox>
                                                <el-checkbox label="新风机接入授权" ></el-checkbox>
                                                <el-checkbox label="资产管理" ></el-checkbox>
                                                <el-checkbox label="南向接口（视频子系统）" ></el-checkbox>
                                                <el-checkbox label="南向接口（门禁子系统）" ></el-checkbox>
                                                <el-checkbox label="南向接口（消防子系统）" ></el-checkbox>
                                                <el-checkbox label="第三方设备开发服务费" ></el-checkbox>
                                                <el-checkbox label="3D建模" ></el-checkbox>
                                            </el-checkbox-group>

                                        </el-form-item>
                                        <el-form-item v-show="jmdShow" class="subtext" style="position: absolute;right: 109px;top: 28px;" label-width="128px" label="面积(平方米):">
                                            <el-input  class="pi" v-model="formInline.area"></el-input>
                                        </el-form-item>
                                        <div class="clear"></div>
                                    </div>
                                    </div>
                                </div>

                                <div v-show="jgShow5">
                                    <!-- 第二十六行 -->
                                    <div class="titlebox">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">工程安装</div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <!-- 第二十七行 -->
                                    <div class="gcaz">
                                        <el-form-item>
                                            <el-checkbox-group v-model="formInline.installGuideList">
                                                <el-checkbox
                                                        class="subTitle"
                                                        label="指导安装"

                                                ></el-checkbox></el-checkbox-group
                                            ></el-form-item>

                                        <el-form-item>
                                            <el-select
                                                    v-model="formInline.installGuideType"
                                                    placeholder=""
                                                    class="oip"
                                            >
                                                <el-option label="现场" value="现场"></el-option>
                                                <el-option label="远程" value="远程"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-checkbox-group v-model="formInline.constructInstallList">
                                                <el-checkbox
                                                        class="subTitle"
                                                        label="施工安装（估算）"
                                                ></el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                        <div class="mustRead">一舟标准施工界面说明（必阅）.</div>
                                        <div class="clear"></div>
                                    </div>
                                </div>

                                <div v-show="jgShow6">
                                    <div class="titlebox">
                                        <div class="titleFix">
                                            <div class="bar"></div>
                                            <div class="title">其他说明</div>
                                        </div>
                                        <div class="clear"></div>
                                        <el-input style="width: 400px !important;margin-left: 10px;"   type="textarea"  class="spaceheight" v-model="formInline.otherRemark2"
                                        />
                                    </div>
                                </div>
                            </div>




                            <el-form-item>
                                <el-button  v-show="nextShow" type="primary" @click="onSubmit">下一步</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

            </div>
            <div style="margin-bottom: 40px;">

            </div >


        </div>
    </div>
</template>

<script>
    import html2canvas from "html2canvas"
    import {
        microdemandAdd,
        projectAdd,
        microdemandChange,
        uploadImg,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    const cityOptions = ['柜体与通道', '配电系统', '制冷系统', '动环监控系统','其它说明','工程安装','厂家描述'];
    export default {
        name: "index",
        data() {
            return {
                factory:'',
                size:'',
                btnLoading:false,
                lightShow:false,
                jmShow:true,
                rlShow1:false,
                rlShow2:true,
                rlShow3:false,
                nextShow:true,
                jgShow1:true,
                jgShow2:true,
                jgShow3:true,
                jgShow4:true,
                jgShow5:false,
                jgShow6:true,
                socket2TypeDisable:false,
                socket2CountDisable:false,
                option2NDisable:false,
                formInline:{
                    lowTemp:'否',
                    selectOptionsList:['柜体与通道', '配电系统', '制冷系统', '动环监控系统','其它说明'],
                    "cabinet1Bracket": "2", //柜体1托盘
                    "cabinet1Count": "13", //柜体1数量
                    "cabinet1Depth": "1200", //柜体1深度
                    "cabinet1UCount": "42", //柜体1高度
                    "cabinet1Plate1u": "10", //柜体1 1u盲板数量
                    "cabinet1Plate2u": "0", //柜体1 2u盲板数量
                    "cabinet1Power": "5", //柜体1功率
                    "cabinet1Tray": "2", //柜体1托架
                    "cabinet1Width": "600", //柜体1宽度
                    "cabinet2Bracket": "2",
                    "cabinet2Count": "0",
                    "cabinet2Depth": "1200",
                    "cabinet2UCount": "42",
                    "cabinet2Plate1u": "10",
                    "cabinet2Plate2u": "0",
                    "cabinet2Power": "5",
                    "cabinet2Tray": "2",
                    "cabinet2Width": "800",
                    "cabinetType": "X5", //柜体类型
                    "conAirSupply": "正前送风", //空调送风方式
                    "conRedundance": "N+1系统", //空调冗余系统
                    "conType": "行级空调", //空调类型
                    "desUpsPowerType": "单路", //UPS输入、输出柜供电方式
                    "heightGap": "700", //机柜顶部至吊顶底部空间
                    "hotFrameHeight": "300", //热围框高度
                    "mCableCount": "1", //M型走线槽/柜
                    "passPosition": "通道外", //通道内，通道外
                    "passSkylight": "直角（手动）", //通道天窗
                    "passType1": "冷通道", //通道类型
                    "passType2": "双列", //单列 双列
                    "passWidth": "1200", //通道宽度
                    "pduWithConnect": "是", //pdu是否带工业连接器
                    "screenSize": "10", //屏幕大小
                    "slidedoorType": "自动平移", //移门类型
                    "upsRedundanceSystem": "N+1系统", //ups冗余系统
                    "upsType": "模块化UPS",//ups类型
                    cityEleType:'双路',
                    upsCapacity:'200',
                    upsRedundanceSystem:'2N系统',
                    batteryType:'铅酸蓄电池',
                    batteryInstallType:'通道外',
                    batteryTime:'30',
                    batteryTimeBase:'按照UPS容量计算',
                    batteryCount:'40',
                    batteryCapacity:'120',
                    batteryLevel:'4',
                    // desUpsPowerType:'双路',
                    desItPowerType:'双路',
                    powerCabinetCapacity:'250',
                    powerCabinetCount:'48',
                    pduStandard:'国标',
                    pduWithConnect:'否',
                    cable:'甲供',
                    otherRemark1:'',

                    conPosition:'通道内',
                    conRedundance:'N+1系统',
                    /*conCapacityType:'',*/
                    conCapacity:'25',
                    conCountType:'自动配置',
                    conCount:'',
                    conCryogen:'是',
                    conFreezShaft:'是',
                    // conAirSupply:'水平送风',
                    conInstallMaterial:'30米',
                    devOps:'基础动环',
                    installGuideType:'',
                    otherRemark2:'',
                    lightColor:'无',
                    functionOptionsList:[],
                    standardAuthorList:['UPS设备', '智能配电柜', '精密空调', '温湿度','水浸', '烟感', '报警器', '通道门禁','视屏监控','短信功能'],
                    installGuideList:[],
                    constructInstallList:[],
                    "pillarPosition": "无柱子",
                    "pillarCount": "",
                    "falseCabinet": "",
                    "inputCurrent": "32",
                    "inputVoltage": "250",
                    "socket1Type": "国标10A三扁",
                    "socket1Count": "8",
                    "socket2Type": "国标16A三扁",
                    "socket2Count": "4",
                    "socketTotal": "12",
                    "area": "",
                },
                /*formInline: {
                    "passType1": "冷通道",
                    "passType2": "单列左",
                    "passWidth": "1200",
                    "passPosition": "通道内",
                    "cabinetType": "X1",
                    "mCableCount": "1只",

                    "cabinet1Width": "600",
                    "cabinet1Depth": "1100",
                    "cabinet1Height": "2000(42U)",
                    "cabinet1Power": "5",
                    "cabinet1Count": "13",
                    "cabinet1Bracket": "2",
                    "cabinet1Tray": "2",
                    "cabinet1Plate1u": "2",
                    "cabinet1Plate2u": "2",

                    "cabinet2Width": "600",
                    "cabinet2Depth": "1100",
                    "cabinet2Height": "2000(42U)",
                    "cabinet2Power": "5",
                    "cabinet2Count": "13",
                    "cabinet2Bracket": "2",
                    "cabinet2Tray": "2",
                    "cabinet2Plate1u": "2",
                    "cabinet2Plate2u": "2",

                    "passSkylight": "标配直角（手动）",
                    "hotFrameHeight": "300",
                    "heightGap": "600",

                    "pillarPosition": "无柱子",
                    "pillarCount": "2",
                    "slidedoorType": "自动平移（标配)",
                    "screenSize": "10寸屏",

                    "conAirSupply": "水平送风", //空调送风方式
                    "conRedundance": "N+1系统", //空调冗余系统
                    "conType": "行级空调", //空调类型
                    "desUpsPowerType": "双路", //UPS输入、输出柜供电方式
                    "pduWithConnect": "是", //pdu是否带工业连接器
                    "upsRedundanceSystem": "N+1系统", //ups冗余系统
                    "upsType": "模块化UPS" //ups类型

                },*/
                checkedCities: ['柜体与通道', '配电系统', '制冷系统', '动环监控系统','其它说明'],
                cities: cityOptions,
                ruleForm: { type: "" },
                ktSelect:true,
                zjShow:true,
                xtShow1:true,
                xtShow2:false,
                xtShow3:false,
                jmdShow:false,
                zzShow:true,

            }
        },
        methods: {
             detectZoom() {
                var ratio = 0,
                    screen = window.screen,
                    ua = navigator.userAgent.toLowerCase();

                if (window.devicePixelRatio !== undefined) {
                    ratio = window.devicePixelRatio;
                }
                else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) {
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                }
                else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                    ratio = window.outerWidth / window.innerWidth;
                }

                if (ratio) {
                    ratio = Math.round(ratio * 100);
                }
                this.size =ratio/50;
            },
           dataURLtoFile(dataurl, filename){

                let arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {
                    type: mime
                });
            },
            toImage(pid,num,factory) {
                debugger

                // 手动创建一个 canvas 标签
                const canvas = document.createElement("canvas");
                // 获取父标签，意思是这个标签内的 DOM 元素生成图片
                // lessonTableImg是给截图范围内的父级元素自定义的ref名称
                let canvasBox = this.$refs.lessonTableImg;
                // 获取父级的宽高
                const width = parseInt(window.getComputedStyle(canvasBox).width);
                const height = parseInt(window.getComputedStyle(canvasBox).height);
                // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
                canvas.width = width * this.size;
                canvas.height = height * this.size;
                canvas.style.width = width + "px";
                canvas.style.height = height + "px";
                const context = canvas.getContext("2d");
                context.scale(2, 2);
                const options = {
                    backgroundColor: "#FFF",  //设置canvas背景图颜色 防止有些图片透明
                    canvas: canvas,
                    useCORS: true,
                };
                html2canvas(canvasBox, options).then((canvas) => {
                    console.log(canvas);

                    // toDataURL 图片格式转成 base64
                     let dataURL= canvas.toDataURL("image/png");
                     console.log(dataURL)
                    let file=this.dataURLtoFile(dataURL, "截图");

                    let formdata = new FormData();
                    formdata.append("file", file);
                    formdata.append("pid", pid);
                    uploadImg(formdata).then((res) => {
                        if (res.code ==200) {
                            console.log(res);
                            if(num==1){
                              this.btnLoading=false
                              this.$router.push({name:'configurationInfo',query: {id:pid,isR:'i',indextype:'1',disCount:'false',factory:factory}})
                            }else {
                              this.$router.push({name:'configurationInfo',query: {id:pid,disCount:'false',factory:factory}})
                            }
                        }
                    });

                    //调用下载
                    // this.downloadImage(dataURL);
                    // 转为file文件
                    // var file = this.dataURLtoFile(dataURL, "封面");
                    // var formdata = new FormData();
                    // formdata.append("file", file);
                    // //file上传到后台
                    // upImgResource(formdata).then((res) => {
                    //     if (res.code == 0) {
                    //         console.log(res);
                    //     }
                    // });
                });
            },
            gl1Change(value){

                this.btnLoading=true
                let obj = {
                    "batteryCount": this.formInline.batteryCount,
                    "batteryTime":this.formInline.batteryTime,
                    "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": value, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": this.formInline.cabinet2Count,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": this.formInline.cabinet2Power,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);
                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false

                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {

                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })
            },
            gl1Change2(value){

                this.btnLoading=true
                let obj = {
                    "batteryTime": value,
                    "batteryCount": this.formInline.batteryCount,
                    "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": this.formInline.cabinet2Count,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": this.formInline.cabinet2Power,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);
                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false

                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {

                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })
            },
            gl1Change3(value){

                this.btnLoading=true
                let obj = {
                    "batteryCount":value,
                    "batteryTime":this.formInline.batteryTime,
                    "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": this.formInline.cabinet2Count,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": this.formInline.cabinet2Power,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);
                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false

                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {

                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })
            },
            gl2Change(value){
                this.btnLoading=true
                let obj = {
                    "batteryCount": this.formInline.batteryCount,
                    "batteryTime":this.formInline.batteryTime,
                    "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": this.formInline.cabinet2Count,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": value,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);

                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false

                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {

                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })
            },
            sl1Change(value){
                this.btnLoading=true
                let obj = {
                    "batteryCount": this.formInline.batteryCount,
                    "batteryTime":this.formInline.batteryTime,
                    "cabinet1Count": value.target.value, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": this.formInline.cabinet2Count,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": this.formInline.cabinet2Power,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);
                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false

                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {

                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })
            },
            sl2Change(value){
                this.btnLoading=true
                let obj = {
                    "batteryCount": this.formInline.batteryCount,
                    "batteryTime":this.formInline.batteryTime,
                    "cabinet1Count": this.formInline.cabinet1Count, //柜体1数量
                    "cabinet1Depth": this.formInline.cabinet1Depth, //柜体1深度
                    "cabinet1UCount": this.formInline.cabinet1UCount,
                    "cabinet1Power": this.formInline.cabinet1Power, //柜体1功率
                    "cabinet1Tray": this.formInline.cabinet1Tray, //柜体1托架
                    "cabinet1Width": this.formInline.cabinet1Width, //柜体1宽度
                    "cabinet2Count": value.target.value,
                    "cabinet2Depth": this.formInline.cabinet2Depth,
                    "cabinet2UCount": this.formInline.cabinet2UCount,
                    "cabinet2Power": this.formInline.cabinet2Power,
                    "cabinet2Width": this.formInline.cabinet2Width,
                    "cabinetType": this.formInline.cabinetType, //柜体类型
                    "desUpsPowerType": this.formInline.desUpsPowerType, //UPS输入、输出柜供电方式
                    "passType2":this.formInline.passType2, //单列 双列
                    "passWidth":this.formInline.passWidth, //通道宽度
                    "selectOptionsList": this.formInline.selectOptionsList,
                    "desUpsPowerType": this.formInline.desUpsPowerType,
                }
                let ss= encrypt(JSON.stringify(obj))
                microdemandChange(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {

                        this.btnLoading=false
                        this.$message.success(`修改成功`);
                        this.formInline= Object.assign(this.formInline,dd.data,);
                        if(this.formInline.upsType=='一体化UPS'){
                            this.jmShow=false
                          this.formInline.upsCapacity='60'
                          this.rlShow1=true
                          this.rlShow2=false
                          this.rlShow3=false
                        }else {
                          this.formInline.upsCapacity='200'
                          this.rlShow1=false
                          this.rlShow2=true
                          this.rlShow3=false
                            this.jmShow=true
                        }
                    }else {
                        this.btnLoading=false
                        alert(dd.msg);
                    }

                })


            },
            dcChnage(value){
                if(value=='32' ){
                    this.formInline.batteryCapacity='65'
                }else if(value=='36' ){
                    this.formInline.batteryCapacity='100'
                }else if(value=='40' ){
                    this.formInline.batteryCapacity='120'
                }else if(value=='44' ){
                    this.formInline.batteryCapacity='150'
                }else if(value=='50' ){
                    this.formInline.batteryCapacity='200'
                }
            },
            gnChange(value){

                if(value.includes('3D建模')){
                    this.jmdShow=true

                }else {
                    this.jmdShow=false
                }
            },
            ymChange(value){

                if(value.includes('自动平移')){
                    this.formInline.screenSize='10'

                }else {
                    this.formInline.screenSize=''
                }
            },
            xtChange(value){
                if(value=='基础动环' ){
                    this.xtShow1=true
                    this.xtShow2=false
                    this.xtShow3=false
                }else if(value=='运维平台' ){
                    this.xtShow1=false
                    this.xtShow2=true
                    this.xtShow3=false
                }else {
                    this.xtShow1=false
                    this.xtShow2=false
                    this.xtShow3=true

                }
            },
            cz1Change(value){
              this.formInline.socketTotal =Number(this.formInline.socket2Count)+Number(value)
            },
            cz2Change(value){
                this.formInline.socketTotal = Number(this.formInline.socket1Count)+Number(value)
            },
            zzChange(value){
                if(value=='无柱子' ){
                    this.zzShow=true
                }else {
                    this.zzShow=false
                }
            },
            highChange(value){
                if(value>=700 ){
                   this.zjShow=false

                }else {
                    this.zjShow=true
                }
            },
            changeColor(value){
                if(value=='X3'){
                    this.formInline.lightColor='无'
                    this.lightShow=true
                }else {
                    this.lightShow=false
                }
            },
            ryChange(value){
                /*if(value=='2N系统' ){
                    this.formInline.desUpsPowerType='双路'
                }else {
                    this.formInline.desUpsPowerType='单路'
                }*/
            },
            rlChange(value){
                if(value=='一体化UPS'){
                    this.jmShow=false
                    this.formInline.upsCapacity='60'
                    this.formInline.passPosition='通道内'
                    this.rlShow1=true
                    this.rlShow2=false
                    this.rlShow3=false
                    this.option2NDisable=true
                    if(this.formInline.upsRedundanceSystem=="2N系统"){
                      this.formInline.upsRedundanceSystem="N+1系统"
                    }
                }else if(value=='模块化UPS'){
                    this.jmShow=true
                    this.formInline.upsCapacity='200'
                    this.formInline.passPosition='通道外'
                    this.rlShow1=false
                    this.rlShow2=true
                    this.rlShow3=false
                    this.option2NDisable=false
                }else {
                    this.jmShow=true
                    this.formInline.upsCapacity='200'
                    this.formInline.passPosition='通道外'
                    this.rlShow1=false
                    this.rlShow2=false
                    this.rlShow3=true
                    this.option2NDisable=false
                }
            },
            ktChange(value){
                if(value=='行级空调'){
                    this.formInline.conPosition='通道内'
                    this.formInline.conAirSupply='正前送风'
                    this.formInline.conCapacity='25'
                    this.ktSelect=true
                }else {
                    this.formInline.conPosition='通道外'
                    this.formInline.conAirSupply='上送风，正面回风'
                    this.formInline.conCapacity='70'
                    this.ktSelect=false
                }
            },
            handleCheckedCitiesChange(value) {

                if(value.length==0){
                    this.nextShow=false
                }else {
                    this.nextShow=true
                }
                if(value.includes('柜体与通道')){
                    this.jgShow1=true
                }else {
                    this.jgShow1=false
                }
                if(value.includes('配电系统')){
                    this.jgShow2=true
                }else {
                    this.jgShow2=false
                }
                if(value.includes('制冷系统')){
                    this.jgShow3=true
                }else {
                    this.jgShow3=false
                }
                if(value.includes('动环监控系统')){
                    this.jgShow4=true
                }else {
                    this.jgShow4=false
                }
                if(value.includes('工程安装')){
                    this.jgShow5=true
                }else {
                    this.jgShow5=false
                }
                if(value.includes('其它说明')){
                    this.jgShow6=true
                }else {
                    this.jgShow6=false
                }
                if(value.includes('厂家描述')){
                    this.factory=1
                }else {
                    this.factory=0
                }
                /*this.checkedCities.map((item,index)=>{
                    if(value.includes(item)){
                        let ss='jgShow'+index
                        this.jgShow1=true
                    }else {
                        this.jgShow1=false
                    }
                })*/
            },
            changeSocket1Type(value){
              if(value=="新国标10A五孔"){
                this.formInline.socket2Type="/"
                this.formInline.socket2Count="0"
                this.socket2TypeDisable=true
                this.socket2CountDisable=true
              }else{
                if(this.formInline.socket2Type=="/"){
                  this.formInline.socket2Type="国标16A三扁"
                  this.formInline.socket2Count="4"
                }
                this.socket2TypeDisable=false
                this.socket2CountDisable=false
              }
            },
            onSubmit(){
               this.btnLoading=true
                console.log(this.formInline.file)
                let ss= encrypt(JSON.stringify(this.formInline))
                microdemandAdd(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    console.log(dd)
                    if (dd.code == 200) {
                        this.$store.commit('updateFormInline',this.formInline)
                        this.toImage(dd.data,1,this.factory)
                    }else {
                        alert(dd.msg);
                    }
                }).finally(()=>{
                  this.btnLoading=false
                })
                // this.toImage(1)
            },
            onSubmitOne(){
                this.btnLoading=true
                let ss= encrypt(JSON.stringify(this.formInline))
                projectAdd(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    console.log(dd)
                    if (dd.code == 200) {
                      this.toImage(dd.data,2,this.factory)
                    }else {
                        alert(dd.msg);
                    }

                })

            },
        },
        mounted(){
            // const c= Object.assign(this.b, this.a);
            // console.log(c,'c')
            if(this.$route.query.isR=='i'){
               this.formInline=this.$store.state.formInline
            }
            this.ktSelect=this.formInline.conType=="行级空调"?true:false
            this.detectZoom()
        }
    }
</script>

<style lang="less" scoped>
    /deep/.el-loading-spinner{
        position: fixed;
    }
    .form-container{
      display: flex;
      .form-section{
        flex: 1;
      }
    }
    .flex-center{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 18px;
    }
    .mb12{
      margin-bottom: 12px !important;
    }
    .compact-el-item-container{
      /deep/ .el-form-item{
        display: flex;
        align-items: flex-start;
        margin-left: 0;
      }
      /deep/ .el-form-item__label{
        text-align: left;
        width: max-content !important;
      }
      /deep/ .el-form-item__content{
        margin-left: 0 !important;
      }
      /deep/ .el-form-item.el-form-item--mini{
        margin-bottom: 0 !important;
      }
      /deep/ .el-form-item:not(:last-of-type){
        margin-right: 18px;
      }
    }
    .cg_main{
        background: white;
        height: 100%;
        display: flow-root;
        .cg_main_son{
            width: 1200px;
            margin: 0px auto;
            min-height: 499px;
            .el-input--mini .el-input__inner {
                height: 35px !important;
                line-height: 35px !important;
            }
            .el-input__suffix,
            .el-form-item--mini .el-form-item__label {
                line-height: 35px !important;
            }
            .topTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 907px;
                margin: 23px 0;

                .el-breadcrumb {
                    font-size: 16px !important;
                }
            }
            .box {
                width: 100%;
            }
            .contain {
                width: 1326px;
                margin: 0 auto;
            }
            .el-row {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .mainTitle {
                width: 100%;
                font-size: 20px;
                font-family: Source Han Sans SC;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
                margin-bottom: 33px;
            }
            .titlebox {
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                min-height: 70px;
                display: flex;
                align-items: center;
            }
            .titleFix {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 138px;
                float: left;

                .title {
                    width: 113px;
                    height: 26px;
                    font-size: 18px;
                    font-family: Source Han Sans SC;
                    font-weight: bold;
                    color: #333333;
                    float: left;
                }
                .bar {
                    width: 4px;
                    height: 26px;
                    background: #e72a32;
                }
            }
            .tableTop {
                div {
                    width: 150px;
                    text-align: center;
                }
            }

            .el-button--primary {
                width: 146px;
                height: 50px;
                margin: 20px auto;
            }
            .topbg {
                width: 1326px;
                height: 50px;
                background: #f5f5f5;
                border: 1px solid #e5e5e5;
                .tableTop {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 1186px;
                    font-size: 16px;
                    font-family: Source Han Sans SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 50px;
                    margin-left: 146px;
                    font-weight: bold;
                    float: left;
                }
            }

            .subTitle {
                width: 124px;
                font-size: 18px;
                font-family: Source Han Sans SC;
                font-weight: bold;
                color: #333333;
                float: left;
                margin-left: 24px;
                line-height: 35px;
            }
            .ltt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 20px 0;
                width: 998px;
                float: left;
                font-size: 13px;
                color: #666666;
            }
            .subtext {
                font-size: 16px;
                font-family: Source Han Sans SC;
                font-weight: bold;
                color: #333333;
                float: left;
                line-height: 35px;
            }
            .bpdsq {
                margin: 10px 36px 0 25px;
            }
            .thirdtext {
                font-size: 16px;
                line-height: 35px;
                color: #333333;
                float: left;
                font-weight: bold;
            }

            .el-form-item__label {
                font-size: 16px !important;
                font-family: Source Han Sans SC;
                font-weight: bold !important;
                color: #333333 !important;
            }
            .borderLine {
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                width: 100%;
                height: 50px;
                padding-top: 13px;
                box-sizing: border-box;
            }
            .tableForm {
                width: 1173px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 29px 0 0 0;
                margin-left: 11px;
            }
            .table2 {
                margin: 20px 0 13px 11px;
            }
            .oi {
                width: 110px !important;
            }
            .oii {
                width: 146px !important;
                margin-right: 33px;
            }
            .oiii {
                width: 169px !important;
                margin-right: 33px;
            }
            .pi {
                width: 80px !important;
            }
            .tableForm {
                .el-form-item {
                    width: 150px;
                }
            }
            .tablecc {
                width: 130px;
                height: 100px;
                float: left;
                padding-top: 6px;
                .subTitle {
                    line-height: 67px;
                }
            }

            .inputWidth {
                width: 140px;
                float: left;
            }
            .el-form-item {
                float: left;
            }
            .twoPart {
                float: left;
                margin-left: 76px;
                width: 452px;
            }
            .onePart {
                width: 1326px;
                border-bottom: 1px solid #e5e5e5;
            }
            .clear {
                clear: both;
            }
            .threePart {
                width: 1326px;
                padding-top: 12px;
                margin-bottom: 11px;
            }
            .part14 {
                margin: 0 0 !important;
                display: flex;
                align-items: center;
            }
            .color {
                height: 35px;
                line-height: 35px;
                float: left;
                margin-left: 77px;
                margin-right: 134px;
            }

            .outside {
                width: 140px;
                margin-right: 50px;
            }
            .tdkd {
                margin-left: 74px;
            }
            .spaceheight {
                width: 90px !important;
                margin: 0;
            }
            .part11 {
                margin: 20px 0 10px 0;
            }
            .xdc {
               writing-mode: tb;
               line-height: 170px;
            }

            .mustRead {
                font-weight: bold;
                margin: 9px 0 0 362px;
            }
            .gcaz {
                padding-top: 20px;
                box-sizing: border-box;
            }
            .gcaz {
                .el-form-item__content {
                    margin-left: 0px !important;
                }
            }
            .hou {
                margin-right: 20px;
            }

            .checkboxml {
                float: left;
                width: 1000px;
                color: #666666;
                font-size: 13px;
            }
            .el-button {
                font-size: 20px !important;
                width: 146px !important;
                height: 50px !important;
            }
            .ktlx {
                writing-mode: tb;
                line-height: 166px;
            }

            .pdxtpart > div {
                margin: 15px 0;
            }

            .ktlx1 {
                width: 928px;
                height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 15px 0;
            }
            .ktlxtype {
                padding-top: 25px;
                box-sizing: border-box;
            }
            .ktlx2 {
                width: 587px;
                height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 30px 0;
            }
            .tdtc {
                margin-left: 150px;
            }
            .qtsm {
                margin-bottom: 20px;
            }
            .gang {
                font-weight: bold;
                line-height: 35px;
            }
            .yzbp {
                width: 1048px;
                color: #666666;
                font-size: 14px;
                line-height: 26px;
                margin-left: 10px;
            }
            .yzbpi {
                width: 1048px;
                color: #666666;
                font-size: 14px;
                margin-left: 10px;
            }
            .jglx {
                margin-left: 2px;
            }
            .lasttext {
                font-size: 14px;
                color: #999999;
                margin-left: -9px;
                margin-bottom: 10px;
            }
            .gcaz {
                .el-checkbox__label {
                    font-weight: bold;
                }
            }
            .oip {
                width: 110px !important;
                margin: 0 20px;
            }

        }
    }
</style>
